import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import OfficeImage from "../components/officeImage"
import BrooklynArtOffice from "../components/brooklynArtOffice"
import BrooklynVibes from "../components/brooklynVibes"
import ChooseYourArt from "../components/chooseYourArt"
import Floor from "../components/floor"
import View3d from "../components/view3d"
import Frame3d from "../components/Frame3d"
import Service from "../components/service"
import Event from "../components/event"
import News from "../components/news"
import Access from "../components/access"
import Contact from "../components/contact"
import Footer from "../components/footer"
import ArtRoom from "../components/artRoom"
import { Helmet } from "react-helmet"
import "../components/font.css"
import AOS from "aos"
import "aos/dist/aos.css"
import CookieConsent from "react-cookie-consent"
// import cookieNotice from "cookie-notice"

const Home = () => {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 800, // values from 0 to 3000, with step 50ms
      duration: 3000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    })
  }
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Roboto:wght@400;500;700;900&display=swap"
          rel="stylesheet"
        />
        <title>A YOTSUYA - アートで部屋を選ぶシェアオフィス</title>
        <meta name="author" content="できるネット編集部" />
        <meta
          name="title"
          content="A YOTSUYA - アートで部屋を選ぶシェアオフィス"
        />
        <meta
          name="description"
          content="新たな「ワークスタイル」を実現する、クリエイティブ・アートオフィス「A YOTSUYA」の公式サイトです。総勢10名のアーティストにより描かれた26面のミューラルアート、ブルックリンをコンセプトにした空間があなたのクリエイティビティを刺激し、新しい働き方を提供します。"
        />
        <meta property="og:title" content="A YOTSUYA" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://a.yotsuya-office.jp/" />
        <meta
          property="og:image"
          content="https://a.yotsuya-office.jp/images/ayotsuya-ogp.png"
        />
        <meta property="og:site_name" content="A YOTSUYA" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LXB6VM7JPL"
        ></script>
        <script>
          {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-LXB6VM7JPL');`}
        </script>
      </Helmet>
      <Layout>
        <CookieConsent
          location="bottom"
          buttonText="閉じる"
          cookieName="myAwesomeCookieName2"
          style={{
            background: "#2B373B",
            fontFamily: "sans-serif",
          }}
          buttonStyle={{
            color: "#fff",
            background: "#f00",
            fontSize: "13px",
            border: "none",
            fontFamily: "sans-serif",
          }}
          expires={150}
        >
          <p style={{ fontFamily: "Noto Sans JP,sans-serif" }}>
            当ウェブサイトはCookieを使用しており、引き続き当サイトを閲覧される場合、当社の個人情報保護方針に同意したものといたします。
          </p>
          <a
            style={{
              textDecoration: "underline",
              fontFamily: "Noto Sans JP,sans-serif",
            }}
            href="https://www.sunfrt.co.jp/policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              style={{
                fontSize: "10px",
                fontFamily: "Noto Sans JP,sans-serif",
              }}
            >
              個人情報保護方針
            </span>
          </a>
        </CookieConsent>
        <Header />
        <OfficeImage />
        <BrooklynArtOffice />
        <ChooseYourArt />
        <Floor />
        <Frame3d />
        <BrooklynVibes />
        <Service />
        <Event />
        <News />
        <Access />
        <Contact />
        <Footer />
      </Layout>
    </>
  )
}

export default Home
