import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import styled from "styled-components"
import "./top.css"
import { Box, Hidden, Typography, Button } from "@material-ui/core"

const Content3 = () => {
  const data = useStaticQuery(graphql`
    query {
      booklynBridge: file(relativePath: { eq: "booklynBridge.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1980, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      blooklynVibes: file(relativePath: { eq: "blooklynVibes.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 454) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spBlooklynVibes: file(relativePath: { eq: "spBrooklynVibes.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 454) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const BrooklynVibesWrap = styled.div`
    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      padding: 56px 0 56px;
    }
    @media screen and (min-width: 600px) and (max-width: 900px) {
      display: flex;
      align-items: center;
      padding: 144px 0 156px;
    }
    @media screen and (min-width: 900px) {
      display: flex;
      align-items: center;
      padding: 144px 0 104px 156px;
    }
  `

  const ContentSize = styled.div`
    color: #e8ded3;
    @media screen and (max-width: 600px) {
      width: 80%;
      margin: auto;
    }
    @media screen and (min-width: 600px) and (max-width: 900px) {
      width: 80%;
      margin: auto;
    }
    @media screen and (min-width: 900px) {
      width: 28%;
    }
  `

  const ImgWrap = styled.div`
    color: #e8ded3;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin: auto;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 600px) and (max-width: 900px) {
      width: 100%;
      margin: auto;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 900px) {
      width: 100%;
      margin-bottom: 20px;
    }
  `

  const content3BackGroundImg = data.booklynBridge.childImageSharp.fluid
  const contentSp3BackGroundImg = data.spBlooklynVibes.childImageSharp.fluid

  return (
    <>
      <Hidden xsDown>
        <BackgroundImage fluid={content3BackGroundImg}>
          <BrooklynVibesWrap data-aos="fade-in">
            <ContentSize>
              <ImgWrap>
                <Img
                  fluid={data.blooklynVibes.childImageSharp.fluid}
                  style={{ objectFit: "cover" }}
                />
              </ImgWrap>
              <div>
                <p
                  className="font-class-3"
                  style={{ letterSpacing: "3px", textAlign: "justify" }}
                >
                  「歴史」と「イノベーション」が行き交うアーティスティックな街、ブルックリン、それはある意味でテーマパークのように、多様な文化が混じり、
                  溶け合う事で生み出される新しい世界線。
                </p>
              </div>
            </ContentSize>
          </BrooklynVibesWrap>
        </BackgroundImage>
      </Hidden>
      <Hidden smUp>
        <BackgroundImage fluid={contentSp3BackGroundImg}>
          <BrooklynVibesWrap data-aos="fade-in">
            <ContentSize>
              <ImgWrap>
                <Img
                  fluid={data.blooklynVibes.childImageSharp.fluid}
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </ImgWrap>
              <div>
                <p
                  className="font-class-3"
                  style={{ letterSpacing: "1px", textAlign: "justify" }}
                >
                  「歴史」と「イノベーション」が行き交うアーティスティックな街、ブルックリン、それはある意味でテーマパークのように、多様な文化が混じり、
                  溶け合う事で生み出される新しい世界線。
                </p>
              </div>
            </ContentSize>
          </BrooklynVibesWrap>
        </BackgroundImage>
      </Hidden>
    </>
  )
}

export default Content3

//spBrooklynVibes.png
