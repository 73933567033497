import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Hidden from "@material-ui/core/Hidden"
import "./top.css"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      Furniture: file(relativePath: { eq: "furniture.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Wifi: file(relativePath: { eq: "wifi.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UtilityCosts: file(relativePath: { eq: "utilityCosts.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Desk: file(relativePath: { eq: "desk.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Device: file(relativePath: { eq: "device.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Projector: file(relativePath: { eq: "projector.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Locker: file(relativePath: { eq: "locker.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Water: file(relativePath: { eq: "water.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Audio: file(relativePath: { eq: "audio.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Microwave: file(relativePath: { eq: "Microwave.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Coffee: file(relativePath: { eq: "coffee.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Refrigerator: file(relativePath: { eq: "refrigerator.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Store: file(relativePath: { eq: "store.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Beer: file(relativePath: { eq: "beer.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service: file(relativePath: { eq: "Service.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const ContentWrap = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 600px) {
      width: calc(100% / 3);
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: calc(100% / 4);
    }
    @media screen and (min-width: 1280px) {
      width: calc(100% / 7);
    }
  `

  const ContentSize = styled.div`
    width: 100%;
    height: 90px;
    text-align: center;
    position: relative;
    @media screen and (max-width: 600px) {
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
    }
    @media screen and (min-width: 1280px) {
    }
  `

  const ContentImage = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
    }
    @media screen and (min-width: 1280px) {
    }
  `

  const ContentString = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    @media screen and (max-width: 600px) {
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
    }
    @media screen and (min-width: 1280px) {
    }
  `
  const ContentsWrap = styled.div`
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;

    @media screen and (max-width: 600px) {
      height: 567px;
      margin-bottom: auto;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 560px;
      height: 360px;
      margin-bottom: auto;
    }
    @media screen and (min-width: 1280px) {
      width: 904px;
      height: 256px;
      margin-bottom: 33px;
    }
  `

  const ServiceWrap = styled.div`
    background-color: #000000;
    color: #e8ded3;

    @media screen and (max-width: 600px) {
      padding: 56px 0 80px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding: 96px 0 151px;
    }
    @media screen and (min-width: 1280px) {
      padding: 96px 0 151px;
    }
  `

  const PageTitle = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      margin-bottom: 24px;
      max-width: 144px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 40px;
      max-width: 255px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 40px;
      max-width: 255px;
    }
  `

  const Explain = styled.p`
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16em;
    text-align: center;

    margin: auto;
    font-size: 10px;
    @media screen and (max-width: 600px) {
      width: 80%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 100%;
    }
    @media screen and (min-width: 1280px) {
      width: 100%;
    }
  `

  const ExplainUp = styled.p`
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.16em;
    text-align: center;
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 100%;
    }
    @media screen and (min-width: 1280px) {
      width: 100%;
    }
  `

  return (
    <ServiceWrap id="service">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        data-aos="fade-in"
      >
        <div>
          <PageTitle>
            <Img fluid={data.service.childImageSharp.fluid} />
          </PageTitle>

          <Hidden mdDown>
            <ContentsWrap>
              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.04px",
                      height: "63.04px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Furniture.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">家具</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "48.77px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Wifi.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">高速Wi-Fi</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "38.32px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.UtilityCosts.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">水道光熱費</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "37.97px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Desk.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">デスクスペース</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "42.73px",
                      height: "63.17px",
                      marginTop: "8px",
                    }}
                  >
                    <Img fluid={data.Device.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">複合機</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "43.94px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Projector.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">プロジェクター</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "48.21px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Locker.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">ロッカー</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "48.32px",
                      height: "63.17px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Water.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">ウォーターサーバー</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "51.87px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Audio.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">無線オーディオ</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "63.17px",
                      height: "48.41px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Microwave.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">電子レンジ</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "57.98px",
                      height: "57.97px",
                      marginTop: "7px",
                    }}
                  >
                    <Img fluid={data.Coffee.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">淹れたてコーヒー</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "43.93px",
                      height: "63.17px",
                      marginTop: "9px",
                    }}
                  >
                    <Img fluid={data.Refrigerator.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">冷蔵庫</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "40.01px",
                      height: "60px",
                      marginTop: "11px",
                    }}
                  >
                    <Img fluid={data.Store.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">無人コンビニ</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>

              <ContentWrap>
                <ContentSize>
                  <ContentImage
                    style={{
                      width: "44.47px",
                      height: "60px",
                      marginTop: "10px",
                    }}
                  >
                    <Img fluid={data.Beer.childImageSharp.fluid} />
                  </ContentImage>
                  <ContentString>
                    <p className="font-class-1">ビール（有料）</p>
                  </ContentString>
                </ContentSize>
              </ContentWrap>
            </ContentsWrap>
          </Hidden>

          <Hidden lgUp>
            <div style={{ marginBottom: "40px" }}>
              <ContentsWrap>
                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.04px",
                        height: "44.81px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Furniture.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">家具</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "48.77px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Wifi.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">高速Wi-Fi</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "38.32px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.UtilityCosts.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">水道光熱費</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "37.97px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Desk.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">デスクスペース</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "42.73px",
                        height: "63.17px",
                        marginTop: "8px",
                      }}
                    >
                      <Img fluid={data.Device.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">複合機</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "43.94px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Projector.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">プロジェクター</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "48.21px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Locker.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">ロッカー</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "48.32px",
                        height: "63.17px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Water.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p
                        className="font-class-1"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize: "12.5px",
                          letterSpacing: "0",
                        }}
                      >
                        ウォーターサーバー
                      </p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "51.87px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Audio.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p
                        className="font-class-1"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize: "12.5px",
                          letterSpacing: "0",
                        }}
                      >
                        無線オーディオ
                      </p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "63.17px",
                        height: "48.41px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Microwave.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">電子レンジ</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "57.98px",
                        height: "57.97px",
                        marginTop: "7px",
                      }}
                    >
                      <Img fluid={data.Coffee.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          fontSize: "12.5px",
                          letterSpacing: "0",
                        }}
                        className="font-class-1"
                      >
                        淹れたてコーヒー
                      </p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "43.93px",
                        height: "63.17px",
                        marginTop: "9px",
                      }}
                    >
                      <Img fluid={data.Refrigerator.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">冷蔵庫</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>
              </ContentsWrap>

              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  justifyContent: "space-around",
                  width: "80%",
                  margin: "auto",
                }}
              >
                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "40.01px",
                        height: "60px",
                        marginTop: "11px",
                      }}
                    >
                      <Img fluid={data.Store.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p className="font-class-1">無人コンビニ</p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>

                <ContentWrap>
                  <ContentSize>
                    <ContentImage
                      style={{
                        width: "44.47px",
                        height: "60px",
                        marginTop: "10px",
                      }}
                    >
                      <Img fluid={data.Beer.childImageSharp.fluid} />
                    </ContentImage>
                    <ContentString>
                      <p
                        className="font-class-1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        ビール（有料）
                      </p>
                    </ContentString>
                  </ContentSize>
                </ContentWrap>
              </div>
            </div>
          </Hidden>

          <div
            style={{
              margin: "auto",
              marginTop: "10px",
              marginBottom: "64.63px",
              textAlign: "center",
            }}
          >
            <ExplainUp>価格についてはお問い合わせください。</ExplainUp>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              className="font-class-8"
              style={{
                width: "255px",
                height: "70px",
                margin: "auto",
                border: "1px solid #E8DED3",
                marginTop: "40pxga",
                borderRadius: "0",
              }}
              onClick={() => navigate("/contacts")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p>CONTACT US</p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </ServiceWrap>
  )
}

export default Content1
