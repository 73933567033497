import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import "./top.css"
import Button from "@material-ui/core/Button"
import WrapTypo from "../components/WrapTypo"

const cardData = [
  {
    isLift: false,
    title: "Facade",
    txt:
      "英国から取り寄せたレンガを基調とし、アートをアイコンとして存在させ、ブルックリンの世界観を忠実に再現したファサード。外壁から溢れ出るクリエイティブは、入居者をテーマパークのようなオフィス空間に迎えいれます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: true,
    title: "Office Rooms",
    txt:
      "22部屋のオフィス一つ一つには国内の第一線で活躍するアーティストのミューラルアートを配置。また、長時間の作業に対応した家具を整えています。",
    button: true,
    buttonText: "VIEW MORE",
    buttonBottomText: "",
    link: "/#art",
  },
  {
    isLift: false,
    title: "Central Balcony",
    txt:
      "日当たりが良く、開放的なルーフトップバルコニー。気分転換を兼ねた執務スペースとして、また定期的に交流イベントを開催予定なので社内外のコミュニケーションスペースとしてご活用いただけます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: true,
    title: "Hub Space",
    txt:
      "デザイン性の高い家具に囲まれたラウンジが各フロアの中央には存在し、ラフな打ち合わせや気分転換の執務スペースとしてご利用いただけます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: false,
    title: "FOOD AND DRINKS",
    txt:
      "Brooklyn Roasting Companyのコーヒーが毎日無料でサーブされ、バリスタが本格マシンで豆から挽いてドリップする、音と香りのパフォーマンスを楽しんでもらえます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: true,
    title: "Event Space",
    txt:
      "スタンディングで100名程が収容可能な地下スペース。音楽イベントやライブイベントなどのアーティスティックなイベントを開催予定です。",
    button: true,
    buttonText: "予約フォーム",
    link: "/contacts",
    buttonBottomText:
      "※イベントスペースに関してのお問い合わせは上記ボタンをクリック",
  },
]

const ContentCard = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  color: #e8ded3;
  position: absolute;
  right: 0;
  bottom: 0;
  @media screen and (max-width: 600px) {
    width: 73%;
    padding: 19px 24px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    width: 38%;
    padding: 41px 27px;
    bottom: 0;
  }
  @media screen and (min-width: 1280px) {
    width: 38%;
    padding: 41px 27px;
    bottom: 0;
  }
`

const ContentFont = styled.div`
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
  }
  @media screen and (min-width: 1280px) {
  }
`

const ContentBotton = styled(Button)`
  border: solid 1px #e8ded3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e8ded3;
  border-radius: 0;

  @media screen and (max-width: 600px) {
    height: 42px;
    width: 126px;
    margin: 8px 0;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    height: 70px;
    width: 255px;
    margin: 10px 0;
  }
  @media screen and (min-width: 1280px) {
    height: 70px;
    width: 255px;
    margin: 10px 0;
  }
`

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      FloorFigure: file(relativePath: { eq: "floorFigure.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 276) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView1: file(relativePath: { eq: "spFView2s.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 303, height: 172) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      FloorView2: file(relativePath: { eq: "spFView2-1s.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 303, height: 172) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      FloorView3: file(relativePath: { eq: "spFView2-2s.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 303, height: 172) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      FloorView4: file(relativePath: { eq: "Group2989s.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 303, height: 172) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      FloorView6: file(relativePath: { eq: "spFView2-3s.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 303, height: 172) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      Floor: file(relativePath: { eq: "FLOOR.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 559) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView7: file(relativePath: { eq: "Group2944s.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 303, height: 172) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const ImageWrap = styled.div`
    @media screen and (max-width: 350px) {
      width: 98%;
      height: 107vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 350px) and (max-width: 480px) {
      width: 93%;
      height: 86vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 480px) and (max-width: 600px) {
      width: 93%;
      height: 63vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 600px) and (max-width: 700px) {
      width: 93%;
      height: 54vw;
      position: relative;
      margin-bottom: 96px;
    }
    @media screen and (min-width: 700px) and (max-width: 750px) {
      width: 93%;
      height: 50vw;
      position: relative;
      margin-bottom: 96px;
    }
    @media screen and (min-width: 750px) and (max-width: 1280px) {
      width: 93%;
      height: 40vw;
      position: relative;
      margin-bottom: 96px;
    }
    @media screen and (min-width: 1280px) {
      width: 93%;
      height: 39vw;
      position: relative;
      margin-bottom: 96px;
    }
  `

  const Content3 = styled(ImageWrap)`
    @media screen and (min-width: 600px) and (max-width: 700px) {
      width: 93%;
      height: 54vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 700px) and (max-width: 800px) {
      width: 93%;
      height: 54vw;
      position: relative;
      margin-bottom: 72px;
    }
  `

  const Content6 = styled(ImageWrap)`
    @media screen and (max-width: 360px) {
      width: 97%;
      height: 98vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 360px) and (max-width: 600px) {
      width: 93%;
      height: 92vw;
      position: relative;
      margin-bottom: 72px;
    }
  `
  const Content5 = styled(ImageWrap)`
    @media screen and (max-width: 320px) {
      width: 93%;
      height: 100vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 320px) and (max-width: 360px) {
      width: 97%;
      height: 90vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 360px) and (max-width: 470px) {
      width: 93%;
      height: 70vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 470px) and (max-width: 600px) {
      width: 93%;
      height: 53vw;
      position: relative;
      margin-bottom: 72px;
    }
  `
  const Content7 = styled(ImageWrap)`
    @media screen and (max-width: 360px) {
      width: 97%;
      height: 110vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 360px) and (max-width: 600px) {
      width: 93%;
      height: 100vw;
      position: relative;
      margin-bottom: 72px;
    }
  `

  const ImageWight = styled.div`
    @media screen and (max-width: 600px) {
      width: 303px;
      height: 172px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 80%;
    }
    @media screen and (min-width: 1280px) {
      width: 80%;
    }
  `

  const ContentWrap = styled.div`
    background-color: #e8ded3;
    color: #1a1a1a;

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      padding: 35px 0 86px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      display: flex;
      align-items: center;
      padding: 35px 0 86px;
    }
    @media screen and (min-width: 1280px) {
      display: flex;
      align-items: center;
      padding: 35px 0 86px;
    }
  `

  const Content1Wrap = styled.div`
    @media screen and (max-width: 600px) {
      width: 100%;
      display: block;
      margin: 0 0 48px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 74%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 105.8px;
    }
    @media screen and (min-width: 1280px) {
      width: 74%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 105.8px;
    }
  `

  const Content1String = styled.div`
    @media screen and (max-width: 600px) {
      width: 88%;
      margin: auto;
      margin-bottom: 52px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 34%;
      margin: 0 0 0 10%;
    }
    @media screen and (min-width: 1280px) {
      width: 38%;
      margin: 0 0 0 10%;
    }
  `

  const PageTitle = styled.div`
    @media screen and (max-width: 600px) {
      max-width: 109px;
      margin: auto;
      margin-bottom: 14px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      max-width: 171px;
      margin-bottom: 26px;
    }
    @media screen and (min-width: 1280px) {
      max-width: 171px;
      margin-bottom: 26px;
    }
  `
  const FigureSize = styled.div`
    @media screen and (max-width: 600px) {
      width: 233px;
      height: 230.46px;
      margin: auto;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 275.4px;
      height: 272.4px;
    }
    @media screen and (min-width: 1280px) {
      width: 275.4px;
      height: 272.4px;
    }
  `

  return (
    <>
      <ContentWrap
        id="floor"
        style={{
          backgroundColor: "#E8DED3",
          display: "flex",
          alignItems: "center",
          color: "1A1A1A",
        }}
      >
        <div>
          {/* ----------content5.1--------- */}
          <div>
            <Content1Wrap>
              <Content1String>
                <PageTitle>
                  <Img fluid={data.Floor.childImageSharp.fluid} />
                </PageTitle>
                <div
                  style={{
                    fontFamily: "Noto Sans JP",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "25px",
                    letterSpacing: "0.16em",
                    textAlign: "justified",
                  }}
                  data-aos="fade-in"
                >
                  <p style={{ color: "#1A1A1A" }}>
                    A
                    YOTSUYAは5つのフロアで構成されており、各階がブルックリンの地名や観光スポットに因んだモチーフが施されてます。施設全体にアートが描かれており、家具や細かい装飾にも拘っています。
                  </p>
                </div>
              </Content1String>
              <FigureSize>
                <Img fluid={data.FloorFigure.childImageSharp.fluid} />
              </FigureSize>
            </Content1Wrap>
          </div>
          {/* ----------content5.2--------- */}

          <ImageWrap>
            <ImageWight>
              <Img fixed={data.FloorView1.childImageSharp.fixed} />
            </ImageWight>
            <Card ListNum={0} />
          </ImageWrap>
          {/* ----------content5.3--------- */}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Content3
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ImageWight
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              >
                <Img fixed={data.FloorView2.childImageSharp.fixed} />
              </ImageWight>
              <Card ListNum={1} />
            </Content3>
          </div>
          {/* ----------content5.4--------- */}
          <ImageWrap>
            <ImageWight>
              <Img fixed={data.FloorView3.childImageSharp.fixed} />
            </ImageWight>
            <Card ListNum={2} />
          </ImageWrap>
          {/* ----------content5.5--------- */}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Content5
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ImageWight
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "space-around",
                  justifyContent: "space-between",
                }}
              >
                <Img fixed={data.FloorView4.childImageSharp.fixed} />
              </ImageWight>
              <Card ListNum={3} />
            </Content5>
          </div>
          {/* ----------content5.6--------- */}
          <Content6>
            <ImageWight>
              <Img fixed={data.FloorView7.childImageSharp.fixed} />
            </ImageWight>
            <Card ListNum={4} />
          </Content6>
          {/* ----------content5.7--------- */}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Content7
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ImageWight
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              >
                <Img fixed={data.FloorView6.childImageSharp.fixed} />
              </ImageWight>
              <Card ListNum={5} />
            </Content7>
          </div>
        </div>
      </ContentWrap>
    </>
  )
}

const Card = props => {
  const num = props.ListNum < cardData.length ? props.ListNum : 0

  return (
    <>
      <ContentCard style={cardData[num].isLift == true ? { left: "0" } : {}}>
        <ContentFont>
          <WrapTypo
            variant="h6"
            color="#E8DED3"
            mt="0"
            mb="0"
            fw="500"
            ls="1px"
            lh="55.3px"
            ff="Brooklyn"
            fs="24px"
            style={{ textAlign: " justify" }}
          >
            {cardData[num].title}
          </WrapTypo>
        </ContentFont>
        <div style={{ border: "1px solid #E8DED3" }}></div>
        <WrapTypo
          color="#E8DED3"
          mb="0"
          fw="700"
          ls="1px"
          lh="21.6px"
          style={{ textAlign: " justify", fontSize: "12px" }}
        >
          {cardData[num].txt}
        </WrapTypo>
        <ContentBotton
          style={cardData[num].button == true ? {} : { display: "none" }}
          className="font-class-8"
          href={cardData[num].link && cardData[num].link}
        >
          <WrapTypo
            color="#E8DED3"
            mt="0"
            mb="0"
            fw="500"
            ls="1px"
            lh="21.6px"
            style={{ textAlign: " justify", fontSize: "12px" }}
          >
            {cardData[num].buttonText}
          </WrapTypo>
        </ContentBotton>
        <WrapTypo
          color="#E8DED3"
          mt="0"
          mb="0"
          fw="700"
          ls="1px"
          lh="21.6px"
          style={
            cardData[num].button == true
              ? { textAlign: " justify", fontSize: "10px" }
              : { display: "none" }
          }
        >
          {cardData[num].buttonBottomText}
        </WrapTypo>
      </ContentCard>
    </>
  )
}

export default Content1
