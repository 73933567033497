import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import "./top.css"
import { Box, Grid } from "@material-ui/core"

const fetchProductData = async () => {
  const fetchProduct = await fetch(
    "https://yotsuya-office.microcms.io/api/v1/event",
    { headers: { "X-API-KEY": "5bc7ab07-9927-425b-a212-51abab8b1964" } }
  )
  try {
    return await fetchProduct.json()
  } catch (e) {}
}

const Content1 = () => {
  const [event, setEvent] = useState([])
  const eventArray = []
  useEffect(() => {
    fetchProductData().then(data => {
      data &&
        data.contents &&
        data.contents.map(c => {
          eventArray.push(c)
        })
      setEvent([...eventArray])
    })
  }, [setEvent])

  const data = useStaticQuery(graphql`
    query {
      eventImage1: file(relativePath: { eq: "1_coming soon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eventImage2: file(relativePath: { eq: "2_coming soon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eventImage3: file(relativePath: { eq: "3_coming soon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 398) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      event: file(relativePath: { eq: "EVENTS.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 208) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const PageTitle = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      margin-bottom: 24px;
      max-width: 144px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 40px;
      max-width: 208px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 40px;
      max-width: 208px;
    }
  `

  const ContentWrap = styled.div`
    background-color: #141414;
    @media screen and (max-width: 600px) {
      padding: 68px 0;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding: 96px 0 156px;
    }
    @media screen and (min-width: 1280px) {
      padding: 96px 0 156px;
    }
  `

  const EventImageWrap = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 85%;
    }
    @media screen and (min-width: 1280px) {
      width: 85%;
    }
  `

  const ImageSize = styled.div`
    @media screen and (max-width: 600px) {
      width: 80%;
      max-width: 299px;
      margin: auto;
      margin-bottom: 16px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 32%;
      max-width: 397.16px;
    }
    @media screen and (min-width: 1280px) {
      width: 32%;
      min-width: 397.16px;
    }
  `

  const ImagePosition = styled.div`
    @media screen and (max-width: 600px) {
      display: block;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      display: flex;
      justify-content: space-between;
    }
    @media screen and (min-width: 1280px) {
      display: flex;
      justify-content: space-between;
    }
  `

  return (
    <Box id="event" bgcolor="#141414" py={[12, 12, 16]}>
      <Box width="40%" maxWidth="300px" m={"auto"} mb={[4, 4, 6]}>
        <Img fluid={data.event.childImageSharp.fluid} />
      </Box>
      <Box m={"auto"} data-aos="fade-up">
        <Grid container justify="center">
          {event.map(c => {
            return (
              <Grid item xs={12} md={4} lg={3}>
                <Box m={"auto"} textAlign="center" my={[2, 2, 4]}>
                  <Link to={c.link}>
                    <img
                      src={c.image.url}
                      style={{
                        width: "300px",
                        height: "200px",
                        margin: "auto",
                      }}
                      alt={c.title}
                    />
                  </Link>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default Content1
