import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import "./top.css"
import WrapTypo from "../components/WrapTypo"

const Content2 = () => {
  const data = useStaticQuery(graphql`
    query {
      content2gackgroundimg: file(relativePath: { eq: "content2bg.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      content2art: file(relativePath: { eq: "content2art.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      content2Brooklyn: file(relativePath: { eq: "content2Brooklyn.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 751) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Font = styled.div`
    @media screen and (max-width: 600px) {
      line-height: normal;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      line-height: 40px;
    }
    @media screen and (min-width: 1280px) {
      line-height: 40px;
    }
  `

  const ContentWrap = styled.div`
    @media screen and (max-width: 600px) {
      padding: 68px 0;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding: 117px 0 80px;
    }
    @media screen and (min-width: 1280px) {
      padding: 117px 0 80px;
    }
  `
  const ContentWidth = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 83%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 68%;
    }
    @media screen and (min-width: 1280px) {
      width: 52%;
    }
  `

  const ImageWidth = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 229px;
      margin-bottom: 19px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 308.16px;
      margin-bottom: 26px;
    }
    @media screen and (min-width: 1280px) {
      width: 308.16px;
      margin-bottom: 26px;
    }
  `

  const AdFont = styled.div`
    font-family: "Brooklyn";
    text-align: center;
    @media screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      font-size: 14px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 14px;
    }
  `

  const BrooklynImage = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      max-width: 752px;
      margin-bottom: 35px;
    }
    @media screen and (min-width: 1280px) {
      max-width: 752px;
      margin-bottom: 35px;
    }
  `

  const BrooklynContent = styled.div`
    @media screen and (max-width: 600px) {
      max-width: 752px;
      margin-bottom: 33px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 62px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 62px;
    }
  `

  const content2BackGroundImg = data.content2gackgroundimg.childImageSharp.fluid

  return (
    <BackgroundImage
      style={{ verticalAlign: "bottom" }}
      fluid={content2BackGroundImg}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#E8DED3",
        }}
      >
        <ContentWrap id="about" data-aos="fade-in">
          <ContentWidth>
            <BrooklynImage>
              <Img fluid={data.content2Brooklyn.childImageSharp.fluid} />
            </BrooklynImage>
            <BrooklynContent>
              <WrapTypo
                color="#fff"
                fw="500"
                ls="1px"
                lh="200%"
                style={{ textAlign: " justify" }}
              >
                いつの時代も、革命や文化の発信地として、劇的な歴史を歩んできたブルックリン。影ではアートが常に寄り添っていました。そんなパワー溢れる街をコンセプトにした空間が四谷に誕生。英国から取り寄せた赤煉瓦のファサードとネオンサインが目印。擁する22のオフィスルームには、精選されたアートが彩ります。アートや、この場へ集まるチャレンジャーとの色鮮やかな出会いは、働き方や感性の解像度を大きく拡張し、周囲まで染め上げて。きっとあなたのクリエーションを遥かな領域へと導きます革命はいつもAから|
              </WrapTypo>
            </BrooklynContent>
          </ContentWidth>
          <div data-aos="fade-in">
            <ImageWidth>
              <Img fluid={data.content2art.childImageSharp.fluid} />
            </ImageWidth>
            <AdFont>
              <h2 style={{ fontFamily: "Brooklyn", color: "#ff2d00" }}>
                2020.11.9 GRAND OPEN
              </h2>
            </AdFont>
          </div>
        </ContentWrap>
      </div>
    </BackgroundImage>
  )
}

export default Content2
