import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import "./top.css"
import Button from "@material-ui/core/Button"
import WrapTypo from "../components/WrapTypo"

const cardData = [
  {
    isLift: false,
    title: "Facade",
    txt:
      "英国から取り寄せたレンガを基調とし、アートをアイコンとして存在させ、ブルックリンの世界観を忠実に再現したファサード。外壁から溢れ出るクリエイティブは、入居者をテーマパークのようなオフィス空間に迎えいれます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: true,
    title: "Office Rooms",
    txt:
      "22部屋のオフィス一つ一つには国内の第一線で活躍するアーティストのミューラルアートを配置。また、長時間の作業に対応した家具を整えています。",
    button: true,
    buttonText: "VIEW MORE",
    buttonBottomText: "",
    link: "/#art",
  },
  {
    isLift: false,
    title: "Central Balcony",
    txt:
      "日当たりが良く、開放的なルーフトップバルコニー。気分転換を兼ねた執務スペースとして、また定期的に交流イベントを開催予定なので社内外のコミュニケーションスペースとしてご活用いただけます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: true,
    title: "Hub Space",
    txt:
      "デザイン性の高い家具に囲まれたラウンジが各フロアの中央には存在し、ラフな打ち合わせや気分転換の執務スペースとしてご利用いただけます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: false,
    title: "Food and Drinks",
    txt:
      "Brooklyn Roasting Companyのコーヒーが毎日無料でサーブされ、バリスタが本格マシンで豆から挽いてドリップする、音と香りのパフォーマンスを楽しんでもらえます。",
    button: false,
    buttonText: "",
    buttonBottomText: "",
  },
  {
    isLift: true,
    title: "Event Space",
    txt:
      "スタンディングで100名程が収容可能な地下スペース。音楽イベントやライブイベントなどのアーティスティックなイベントを開催予定です。",
    button: true,
    buttonText: "予約フォーム",
    link: "/contacts",
    buttonBottomText:
      "※イベントスペースに関してのお問い合わせは上記ボタンをクリック",
  },
]

const ContentCard = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  color: #e8ded3;
  position: absolute;
  right: 0;
  bottom: 0;
  @media screen and (max-width: 600px) {
    width: 60%;
    padding: 19px 24px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    width: 38%;
    padding: 41px 27px;
    bottom: 0;
  }
  @media screen and (min-width: 1280px) {
    width: 38%;
    padding: 41px 27px;
    bottom: 0;
  }
`

const ContentFont = styled.div`
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
  }
  @media screen and (min-width: 1280px) {
  }
`

const Font = styled.div`
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.16em;
  text-align: "justified";
  margin-top: 16px;
`

const ContentBotton = styled(Button)`
  border: solid 1px #e8ded3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e8ded3;
  border-radius: 0;

  @media screen and (max-width: 600px) {
    height: 42px;
    width: 126px;
    margin: 8px 0;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    height: 70px;
    width: 255px;
    margin: 10px 0;
  }
  @media screen and (min-width: 1280px) {
    height: 70px;
    width: 255px;
    margin: 10px 0;
  }
`

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      FloorFigure: file(relativePath: { eq: "floorFigure.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 276) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView1: file(relativePath: { eq: "facade.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView2: file(relativePath: { eq: "officerooms.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView3: file(relativePath: { eq: "centralbalcony.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView4: file(relativePath: { eq: "Group2992p.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView6: file(relativePath: { eq: "b1.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Floor: file(relativePath: { eq: "FLOOR.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 559) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FloorView7: file(relativePath: { eq: "Group2915.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const ImageWrap = styled.div`
    @media screen and (max-width: 350px) {
      width: 93%;
      height: 130vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 350px) and (max-width: 480px) {
      width: 93%;
      height: 86vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 480px) and (max-width: 600px) {
      width: 93%;
      height: 63vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 600px) and (max-width: 700px) {
      width: 93%;
      height: 54vw;
      position: relative;
      margin-bottom: 96px;
    }
    @media screen and (min-width: 700px) and (max-width: 750px) {
      width: 93%;
      height: 50vw;
      position: relative;
      margin-bottom: 96px;
    }
    @media screen and (min-width: 750px) and (max-width: 1280px) {
      width: 93%;
      height: 40vw;
      position: relative;
      margin-bottom: 96px;
    }
    @media screen and (min-width: 1280px) {
      width: 93%;
      height: 39vw;
      position: relative;
      margin-bottom: 96px;
    }
  `

  const Content3 = styled(ImageWrap)`
    @media screen and (min-width: 600px) and (max-width: 700px) {
      width: 93%;
      height: 54vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 700px) and (max-width: 800px) {
      width: 93%;
      height: 54vw;
      position: relative;
      margin-bottom: 72px;
    }
  `

  const Content6 = styled(ImageWrap)`
    @media screen and (max-width: 360px) {
      width: 93%;
      height: 80vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 360px) and (max-width: 600px) {
      width: 93%;
      height: 71vw;
      position: relative;
      margin-bottom: 72px;
    }
  `
  const Content5 = styled(ImageWrap)`
    @media screen and (max-width: 360px) {
      width: 93%;
      height: 100vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 320px) and (max-width: 360px) {
      width: 93%;
      height: 90vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 360px) and (max-width: 470px) {
      width: 93%;
      height: 70vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 470px) and (max-width: 600px) {
      width: 93%;
      height: 53vw;
      position: relative;
      margin-bottom: 72px;
    }
  `
  const Content7 = styled(ImageWrap)`
    @media screen and (max-width: 360px) {
      width: 93%;
      height: 90vw;
      position: relative;
      margin-bottom: 72px;
    }
    @media screen and (min-width: 360px) and (max-width: 600px) {
      width: 93%;
      height: 74vw;
      position: relative;
      margin-bottom: 72px;
    }
  `

  const ImageWight = styled.div`
    @media screen and (max-width: 600px) {
      width: 80%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 80%;
    }
    @media screen and (min-width: 1280px) {
      width: 80%;
    }
  `

  const ContentWrap = styled.div`
    background-color: #e8ded3;
    color: #1a1a1a;

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      padding: 35px 0 86px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      display: flex;
      align-items: center;
      padding: 35px 0 86px;
    }
    @media screen and (min-width: 1280px) {
      display: flex;
      align-items: center;
      padding: 35px 0 86px;
    }
  `

  const Content1Wrap = styled.div`
    @media screen and (max-width: 600px) {
      width: 100%;
      display: block;
      margin: 0 0 48px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 74%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 105.8px;
    }
    @media screen and (min-width: 1280px) {
      width: 74%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 105.8px;
    }
  `

  const Content1String = styled.div`
    @media screen and (max-width: 600px) {
      width: 88%;
      margin: auto;
      margin-bottom: 52px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 34%;
      margin: 0 0 0 10%;
    }
    @media screen and (min-width: 1280px) {
      width: 38%;
      margin: 0 0 0 10%;
    }
  `

  const PageTitle = styled.div`
    @media screen and (max-width: 600px) {
      max-width: 109px;
      margin: auto;
      margin-bottom: 14px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      max-width: 171px;
      margin-bottom: 26px;
    }
    @media screen and (min-width: 1280px) {
      max-width: 171px;
      margin-bottom: 26px;
    }
  `
  const FigureSize = styled.div`
    @media screen and (max-width: 600px) {
      width: 233px;
      height: 230.46px;
      margin: auto;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 275.4px;
      height: 272.4px;
    }
    @media screen and (min-width: 1280px) {
      width: 275.4px;
      height: 272.4px;
    }
  `

  return (
    <>
      <ContentWrap
        id="floor"
        style={{
          backgroundColor: "#E8DED3",
          display: "flex",
          alignItems: "center",
          color: "1A1A1A",
        }}
      >
        <div style={{ width: "100%" }}>
          <div>
            <Content1Wrap data-aos="fade-right">
              <Content1String>
                <PageTitle>
                  <Img fluid={data.Floor.childImageSharp.fluid} />
                </PageTitle>
                <div
                  style={{
                    fontFamily: "Noto Sans JP",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "25px",
                    letterSpacing: "0.16em",
                    textAlign: "justified",
                    color: "#1A1A1A",
                  }}
                  data-aos="fade-in"
                >
                  <p style={{ color: "#1A1A1A" }}>
                    A
                    YOTSUYAは5つのフロアで構成されており、各階がブルックリンの地名や観光スポットに因んだモチーフが施されてます。施設全体にアートが描かれており、家具や細かい装飾にも拘っています。
                  </p>
                </div>
              </Content1String>
              <FigureSize>
                <Img fluid={data.FloorFigure.childImageSharp.fluid} />
              </FigureSize>
            </Content1Wrap>
          </div>

          <ImageWrap data-aos="fade-in">
            <ImageWight>
              <Img fluid={data.FloorView1.childImageSharp.fluid} />
            </ImageWight>
            <Card ListNum={0} />
          </ImageWrap>

          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            data-aos="fade-in"
          >
            <Content3
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "93%",
                  position: "relative",
                  right: "0",
                }}
              >
                <ImageWight
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                >
                  <Img fluid={data.FloorView2.childImageSharp.fluid} />
                </ImageWight>
                <Card ListNum={1} />
              </div>
            </Content3>
          </div>

          <ImageWrap data-aos="fade-in">
            <ImageWight>
              <Img fluid={data.FloorView3.childImageSharp.fluid} />
            </ImageWight>
            <Card ListNum={2} />
          </ImageWrap>

          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            data-aos="fade-in"
          >
            <Content5
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "93%",
                  position: "relative",
                  right: "0",
                }}
              >
                <ImageWight
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                >
                  <Img fluid={data.FloorView4.childImageSharp.fluid} />
                </ImageWight>
                <Card ListNum={3} />
              </div>
            </Content5>
          </div>

          <Content6 data-aos="fade-in">
            <ImageWight>
              <Img fluid={data.FloorView7.childImageSharp.fluid} />
            </ImageWight>
            <Card ListNum={4} />
          </Content6>

          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            data-aos="fade-in"
          >
            <Content7
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "93%",
                  position: "relative",
                  right: "0",
                }}
              >
                <ImageWight
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                >
                  <Img fluid={data.FloorView6.childImageSharp.fluid} />
                </ImageWight>
                <Card ListNum={5} />
              </div>
            </Content7>
          </div>
        </div>
      </ContentWrap>
    </>
  )
}

const Card = props => {
  const num = props.ListNum < cardData.length ? props.ListNum : 0

  return (
    <>
      <ContentCard style={cardData[num].isLift == true ? { left: "0" } : {}}>
        <ContentFont>
          <WrapTypo
            variant="h4"
            color="#E8DED3"
            mt="0"
            mb="16px"
            fw="500"
            ls="1px"
            lh="55.3px"
            ff="Brooklyn"
            fs="48px"
            style={{ textAlign: " justify" }}
          >
            {cardData[num].title}
          </WrapTypo>
        </ContentFont>
        <div style={{ border: "1px solid #E8DED3" }}></div>
        <div style={{ marginTop: "16px" }}>
          <WrapTypo
            color="#E8DED3"
            mt="0"
            mb="10px"
            fw="500"
            ls="0.16em"
            lh="24.96px"
            style={{ textAlign: " justify", fontSize: "16px" }}
          >
            {cardData[num].txt}
          </WrapTypo>
        </div>
        <ContentBotton
          style={cardData[num].button == true ? {} : { display: "none" }}
          className="font-class-8"
          href={cardData[num].link && cardData[num].link}
        >
          <WrapTypo
            color="#E8DED3"
            mt="0"
            mb="0"
            fw="700"
            ls="1px"
            lh="21.6px"
            style={{ textAlign: " justify", fontSize: "18px" }}
          >
            {cardData[num].buttonText}
          </WrapTypo>
        </ContentBotton>
        <p style={cardData[num].button == true ? {} : { display: "none" }}>
          {cardData[num].buttonBottomText}
        </p>
      </ContentCard>
    </>
  )
}

export default Content1
