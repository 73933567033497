import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import "./top.css"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 246) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const ContactString = styled.div`
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.16em;
    text-align: left;

    margin: auto;
    margin-bottom: 40px;
    @media screen and (max-width: 600px) {
      font-size: 12px;
      width: 83%;
    }
    @media screen and (min-width: 600px) and (max-width: 1025px) {
      font-size: 16px;
      width: 83%;
    }
    @media screen and (min-width: 1025px) {
      max-width: 814px;
      width: 83%;
      font-size: 16px;
    }
  `
  const PageTitle = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      margin-bottom: 24px;
      max-width: 144px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 40px;
      max-width: 255px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 40px;
      max-width: 255px;
    }
  `

  return (
    <div
      style={{
        backgroundColor: "#242424",
        height: "544px",
        color: "#E8DED3",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div>
          <PageTitle>
            <Img fluid={data.contact.childImageSharp.fluid} />
          </PageTitle>
          <ContactString>
            <p>オフィス契約をご希望の方は、CONTACTフォームからご連絡下さい。</p>
          </ContactString>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                width: "255px",
                height: "70px",
                margin: "auto",
                backgroundColor: "#FF2D00",
                textAlign: "center",
                borderRadius: "0",
              }}
              onClick={() => navigate("/contacts")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p className="font-class-8" style={{ color: "#E8DED3" }}>
                  CONTACT US
                </p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content1
