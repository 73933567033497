import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import "./top.css"
import ChoiceArt from "../components/ChoiceArt"
import WrapTypo from "./WrapTypo"

const WSTypo = styled(WrapTypo)`
  @media screen and (min-width: 300px) and (max-width: 768px) {
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }
  @media screen and (min-width: 1024px) {
  }
`

const Content1 = props => {
  const data = useStaticQuery(graphql`
    query {
      artImage1: file(relativePath: { eq: "MaskGroup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      artImage2: file(relativePath: { eq: "IMG_8534.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      artImage3: file(relativePath: { eq: "IMG_8526.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      artImage4: file(relativePath: { eq: "IMG_8499.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chooseYourArt: file(relativePath: { eq: "chooseYourArt.png" }) {
        childImageSharp {
          fluid(maxWidth: 509) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const artImage1 = data.artImage1.childImageSharp.fluid
  const artImage2 = data.artImage2.childImageSharp.fluid
  const artImage3 = data.artImage3.childImageSharp.fluid
  const artImage4 = data.artImage4.childImageSharp.fluid

  const artItems = [
    artImage1,
    artImage2,
    artImage3,
    artImage4,
    artImage1,
    artImage2,
    artImage3,
    artImage4,
    artImage1,
    artImage2,
    artImage3,
    artImage4,
    artImage1,
    artImage2,
    artImage3,
    artImage4,
    artImage1,
    artImage2,
    artImage3,
    artImage4,
    artImage1,
    artImage2,
  ]

  const ContentWrap = styled.div`
    background-color: #141414;
    color: #e8ded3;
    @media screen and (max-width: 600px) {
      padding: 56px 0 78px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding: 96px 0 156px;
    }
    @media screen and (min-width: 1280px) {
      padding: 96px 0 156px;
    }
  `

  const PageTitle = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
      max-width: 300px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 20px;
      max-width: 509px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 20px;
      max-width: 509px;
    }
  `

  const FontSize = styled.div`
    margin: auto;
    text-align: center;
    @media screen and (max-width: 600px) {
      margin-bottom: 24px;
      max-width: 286px;
      font-size: 14px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 40px;
      max-width: 400px;
      font-size: 16px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 40px;
      max-width: 400px;
      font-size: 16px;
    }
  `

  const ArtImage = styled(Img)`
    vertical-align: bottom;
    @media screen and (max-width: 600px) {
      width: calc(100% / 3);
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: calc(100% / 4);
    }
    @media screen and (min-width: 1280px) {
      width: calc(100% / 5);
    }
  `

  const ArtContentSize = styled.div`
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 600px) {
      width: 86%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 63%;
      height: 94%;
    }
    @media screen and (min-width: 1280px) {
      width: 70%;
      height: 70%;
    }
  `

  return (
    <ContentWrap id="art">
      <div
        data-aos="fade-up"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              maxWidth: "509px",
              margin: "auto",
              marginBottom: "40px",
            }}
          >
            <PageTitle>
              <Img fluid={data.chooseYourArt.childImageSharp.fluid} />
            </PageTitle>
            <FontSize>
              <WSTypo className="font-class-3" color="#fff" fs="12px">
                下記リストからお好きなアートを選択してください
                <br />
                画像を押すと詳細が見れます
              </WSTypo>
            </FontSize>
          </div>
          <ChoiceArt />
          {/* <ArtContentSize>
            {artItems.map(d => {
              return <ArtImage fluid={d} />
            })}
          </ArtContentSize> */}
        </div>
      </div>
    </ContentWrap>
  )
}

export default Content1
