import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Hidden from "@material-ui/core/Hidden"
import styled from "styled-components"
import "./top.css"
import WrapTypo from "./WrapTypo"

const WSTypo = styled(WrapTypo)`
  @media screen and (min-width: 300px) and (max-width: 768px) {
    white-space: "pre-wrap";
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    white-space: "normal";
  }
  @media screen and (min-width: 1024px) {
    white-space: "normal";
  }
`

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      access: file(relativePath: { eq: "ACCESS.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 201) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const AccessWrap = styled.div`
    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 346px;
      padding: 56px 0 80px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding: 56px 0 80px;
    }
    @media screen and (min-width: 1280px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 621px;
      padding: 96px 0 156px;
    }
  `

  const AccessTitle = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      max-width: 129px;
      margin-bottom: 24px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      max-width: 201px;
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1280px) {
      max-width: 201px;
      margin-bottom: 40px;
    }
  `

  const GoogleMap = styled.div`
    @media screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 16px;
    }
  `

  const Address = styled.div`
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.16em;
    text-align: center;

    text-align: center;
    margin: auto;

    @media screen and (max-width: 600px) {
      width: 66%;
      font-size: 12px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1280px) {
      font-size: 16px;
    }
  `

  return (
    <div style={{ backgroundColor: "#E8DED3" }}>
      <AccessWrap id="access">
        <div>
          <AccessTitle>
            <Img fluid={data.access.childImageSharp.fluid} />
          </AccessTitle>
          <Hidden mdDown>
            <GoogleMap>
              <iframe
                title="google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.5305183981254!2d139.7261399925043!3d35.68856040704263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c8b315d254b%3A0x731284031ab06a1f!2z44CSMTYwLTAwMDMg5p2x5Lqs6YO95paw5a6_5Yy65Zub6LC35pys5aGp55S677yS4oiS77yY!5e0!3m2!1sja!2sjp!4v1604104962062!5m2!1sja!2sjp"
                width="739.68"
                height="415"
                frameborder="0"
                style={{
                  border: "0",
                  filter: "grayscale(100%)",
                }}
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
            </GoogleMap>
          </Hidden>
          <Hidden lgUp xsDown>
            <GoogleMap>
              <iframe
                title="google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.5305183981254!2d139.7261399925043!3d35.68856040704263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c8b315d254b%3A0x731284031ab06a1f!2z44CSMTYwLTAwMDMg5p2x5Lqs6YO95paw5a6_5Yy65Zub6LC35pys5aGp55S677yS4oiS77yY!5e0!3m2!1sja!2sjp!4v1604104962062!5m2!1sja!2sjp"
                width="550"
                height="286"
                frameborder="0"
                style={{
                  border: "0",
                  filter: "grayscale(100%)",
                }}
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
            </GoogleMap>
          </Hidden>
          <Hidden smUp>
            <GoogleMap>
              <iframe
                title="google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.5305183981254!2d139.7261399925043!3d35.68856040704263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c8b315d254b%3A0x731284031ab06a1f!2z44CSMTYwLTAwMDMg5p2x5Lqs6YO95paw5a6_5Yy65Zub6LC35pys5aGp55S677yS4oiS77yY!5e0!3m2!1sja!2sjp!4v1604104962062!5m2!1sja!2sjp"
                width="80%"
                height="80%"
                frameborder="0"
                style={{
                  border: "0",
                  filter: "grayscale(100%)",
                }}
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
            </GoogleMap>
          </Hidden>
          <Address>
            <Hidden smUp>
              <WSTypo fs="12px" style={{ fontSize: "12px" }}>
                A YOTSUYA / 2-8 Yotsuyahonshiocho Shinjuku-Ku, Tokyo 160-0003
              </WSTypo>
              <WSTypo mt="0" style={{ fontSize: "12px" }}>
                〒160-0003 東京都新宿区四谷本塩町2-8
              </WSTypo>
            </Hidden>
            <Hidden xsDown mdUp>
              <WSTypo fs="16px" style={{ fontSize: "16px" }}>
                A YOTSUYA / 2-8 Yotsuyahonshiocho Shinjuku-Ku, Tokyo 160-0003
              </WSTypo>
              <WSTypo
                mt="0"
                fs="16px"
                style={{ fontSize: "16px", margin: "auto" }}
              >
                〒160-0003 東京都新宿区四谷本塩町2-8
              </WSTypo>
            </Hidden>
            <Hidden smDown>
              <WSTypo fs="22px" style={{ fontSize: "16px" }}>
                A YOTSUYA / 2-8 Yotsuyahonshiocho Shinjuku-Ku, Tokyo 160-0003
              </WSTypo>
              <WSTypo
                mt="0"
                fs="22px"
                style={{ fontSize: "16px", margin: "auto" }}
              >
                〒160-0003 東京都新宿区四谷本塩町2-8
              </WSTypo>
            </Hidden>
          </Address>
        </div>
      </AccessWrap>
    </div>
  )
}

export default Content1
