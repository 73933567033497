import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import WrapTypo from "../components/WrapTypo"
import "./top.css"
import { Box, Hidden, Button, IconButton } from "@material-ui/core"
import { VolumeUp, VolumeOff } from "@material-ui/icons"
import styled from "styled-components"
import Helmet from "react-helmet"

const RootBox = styled(Box)`
  @supports (-webkit-touch-callout: none) {
    body {
      /* Safari用のハック */
      height: -webkit-fill-available;
    }
  }
`

const Content = () => {
  const videoRef = useRef(null)
  const videoRefSp = useRef(null)
  const [isMute, setMute] = useState(true)
  return (
    <>
      <Box className="top">
        <Hidden smDown>
          <RootBox
            width="100%"
            pt={"46px"}
            bgcolor="#020202"
            position="relative"
          >
            <video
              ref={videoRef}
              width="100%"
              muted
              muted={true}
              autoPlay
              loop
              preLoad
              webkitPlaysinline
              playsInline
              src="YotsuyaOffice_full_15Mbps_2.mp4"
              type="video/mp4"
              style={{ marigin: 0, padding: 0 }}
            />
            <Box position="absolute" bottom="16px" right="16px" zIndex="990">
              <IconButton
                size="medium"
                onClick={_ => {
                  videoRef.current.muted = false
                  isMute
                    ? (videoRef.current.volume = 0.5)
                    : (videoRef.current.volume = 0)
                  setMute(c => !c)
                }}
              >
                {isMute ? (
                  <VolumeOff fontSize="large" />
                ) : (
                  <VolumeUp fontSize="large" />
                )}
              </IconButton>
            </Box>
          </RootBox>
        </Hidden>
        <Hidden mdUp>
          <RootBox
            width="100%"
            pt={"46px"}
            bgcolor="#020202"
            position="relative"
          >
            <video
              ref={videoRefSp}
              width="100%"
              muted
              muted={true}
              autoPlay
              loop
              preLoad
              webkitPlaysinline
              playsInline
              src="YotsuyaOffice_full_15Mbps_2.mp4"
              type="video/mp4"
              style={{ marigin: 0, padding: 0 }}
            />
            <Box position="absolute" bottom="0" right="0" zIndex="990">
              <IconButton
                onClick={_ => {
                  isMute
                    ? (videoRefSp.current.muted = false)
                    : (videoRefSp.current.muted = true)
                  isMute
                    ? (videoRefSp.current.volume = 0.5)
                    : (videoRefSp.current.volume = 0)
                  setMute(c => !c)
                }}
              >
                {isMute ? <VolumeOff /> : <VolumeUp />}
              </IconButton>
            </Box>
          </RootBox>
        </Hidden>
      </Box>
    </>
  )
}

export default Content
