import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Box } from "@material-ui/core"
import { Helmet } from "react-helmet"

const View = () => {
  const data = useStaticQuery(graphql`
    query {
      view3d: file(relativePath: { eq: "view3d.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const PageTitle = styled.div`
    margin: auto;
    @media screen and (max-width: 600px) {
      margin-bottom: 24px;
      max-width: 144px;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      margin-bottom: 40px;
      max-width: 255px;
    }
    @media screen and (min-width: 1280px) {
      margin-bottom: 40px;
      max-width: 255px;
    }
  `
  return (
    <>
      <Helmet>
        <script
          async
          src="https://r68330076.theta360.biz/t_widgets.js"
          charset="utf-8"
        ></script>
      </Helmet>
      <Box pt={10} bgcolor="#1A1A1A">
        <PageTitle data-aos="fade-in">
          <Img fluid={data.view3d.childImageSharp.fluid} />
        </PageTitle>
        <Box
          width="90%"
          margin={"auto"}
          pb={10}
          style={{ textAlign: "center" }}
          display="flex"
          justifyContent="center"
          bgcolor="#1A1A1A"
          data-aos="dade-in"
        >
          <blockquote
            data-width="958"
            data-height="478"
            class="ricoh-theta-tour-image"
          >
            <a href="https://r68330076.theta360.biz/t/c5eeb372-20e5-11eb-b4db-0aec2ac0ed7a-1"></a>
          </blockquote>
        </Box>
      </Box>
    </>
  )
}
export default View
