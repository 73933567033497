import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import "./top.css"
import { Button, Box } from "@material-ui/core"

const fetchProductData = async () => {
  const fetchProduct = await fetch(
    "https://yotsuya-office.microcms.io/api/v1/news",
    { headers: { "X-API-KEY": "5bc7ab07-9927-425b-a212-51abab8b1964" } }
  )
  try {
    return await fetchProduct.json()
  } catch (e) {}
}

const NewsWrap = styled.div`
  background-color: #1a1a1a;
  color: #e8ded3;

  @media screen and (max-width: 600px) {
    padding: 56px 0 0px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
  }
  @media screen and (min-width: 1280px) {
    padding: 96px 0 0px;
  }
`

const NewsContents = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    margin-bottom: 0px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    width: 500px;
    margin-bottom: 0px;
  }
  @media screen and (min-width: 1280px) {
    width: 1022px;
    margin-bottom: 0px;
  }
`

const NewsSize = styled.div`
  @media screen and (max-width: 380px) {
    width: 100%;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 380px) and (max-width: 600px) {
    width: 327px;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    width: 100%;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 1280px) {
    width: 100%;
    margin-bottom: 24px;
  }
`

const Content = styled.div`
  font-family: "Noto Sans JP";

  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.16em;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`

const Days = styled.div`
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 550;
  line-height: 25px;
  letter-spacing: 0.16em;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 14px;
  }
`

const Notice = styled.div`
  font-family: "Noto Sans JP";

  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.16em;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 14px;
  }
`

const NoticeSize = styled.div`
  background-color: #ff2d00;
  @media screen and (max-width: 600px) {
    width: 91px;
    height: 22px;
    margin: 0 35px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    width: 113px;
    height: 29px;
    margin: 0 10px;
  }
  @media screen and (min-width: 1280px) {
    width: 113px;
    height: 29px;
    margin: 0 19px;
  }
`
const PageTitle = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    margin-bottom: 40px;
    max-width: 98px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    margin-bottom: 80px;
    max-width: 208px;
  }
  @media screen and (min-width: 1280px) {
    margin-bottom: 80px;
    max-width: 208px;
  }
`

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      news: file(relativePath: { eq: "NEWS.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 153) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [newsList, setNewsList] = useState([])
  const newsArray = []
  const [choiceArt, setChoiceArt] = useState(-1)
  useEffect(() => {
    fetchProductData().then(data => {
      data &&
        data.contents &&
        data.contents.map(c => {
          newsArray.push(c)
        })
      setNewsList([...newsArray])
    })
  }, [setNewsList])

  return (
    <NewsWrap
      id="news"
      style={{
        backgroundColor: "#1A1A1A",
        color: "#E8DED3",
        padding: "96px 0 96px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "90%" }}>
          <PageTitle>
            <Img fluid={data.news.childImageSharp.fluid} />
          </PageTitle>
          <Box width="100%" m={"auto"} maxWidth="800px">
            {newsList.map(c => {
              return (
                <News
                  data={{
                    date: c.date,
                    text: c.title,
                    link: c.link ? c.link : "",
                  }}
                />
              )
            })}
          </Box>
          {/* <div style={{ display: "flex" }}>
            <Button
              className="font-class-8"
              style={{
                width: "255px",
                height: "70px",
                margin: "auto",
                border: "1px solid #E8DED3",
                textAlign: "center",
                marginTop: "40pxga",
                borderRadius: "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p>VIEW MORE</p>
              </div>
            </Button>
          </div> */}
        </div>
      </div>
    </NewsWrap>
  )
}

const News = props => {
  const data = props.data
  return (
    <>
      {data.link === "" ? (
        <NewsSize>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              background: "rgba(255, 255, 255, 0.08)",
              padding: "9px 0",
            }}
          >
            <div style={{ width: "90%", margin: "auto" }}>
              <div style={{ display: "flex" }}>
                <Days
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data.date}
                </Days>
                <NoticeSize>
                  <Notice
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    お知らせ
                  </Notice>
                </NoticeSize>
              </div>
              <Content style={{ margin: "5px 0 0" }}>
                <p>{data.text}</p>
              </Content>
            </div>
          </div>
        </NewsSize>
      ) : (
        <NewsSize>
          <a href={data.link && data.link}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                background: "rgba(255, 255, 255, 0.08)",
                padding: "9px 0",
              }}
            >
              <div style={{ width: "90%", margin: "auto" }}>
                <div style={{ display: "flex" }}>
                  <Days
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {data.date}
                  </Days>
                  <NoticeSize>
                    <Notice
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      お知らせ
                    </Notice>
                  </NoticeSize>
                </div>
                <Content style={{ margin: "5px 0 0" }}>
                  <p>{data.text}</p>
                </Content>
              </div>
            </div>
          </a>
        </NewsSize>
      )}
    </>
  )
}

export default Content1
