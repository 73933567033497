import React from "react"
import "./top.css"
import Hidden from "@material-ui/core/Hidden"
import SpFloor from "../components/spFoor"
import TbFloor from "../components/tbFoor"
import PcFloor from "../components/pcFoor"
import { Box } from "@material-ui/core"

const Content1 = () => {
  return (
    <>
      <Box>
        <Hidden mdDown>
          <PcFloor />
        </Hidden>
        <Hidden xsDown lgUp>
          <TbFloor />
        </Hidden>
        <Hidden smUp>
          <SpFloor />
        </Hidden>
      </Box>
    </>
  )
}

export default Content1
